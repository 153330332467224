
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";

export default defineComponent({
  name: "admin-payments-table",
  components: {},
  props: {
    widgetClasses: String
  },
  data() {
    return {
      payments: []
    };
  },
  created() {
    this.getPayments();
  },
  methods: {
    getDetails(id) {
      console.log(id);
      this.$emit("close");
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getPayments() {
      //Fetch list of all payments
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.SUPERADMIN_PAYMENTS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.payments = data.data;
            setTimeout(() => {
              this.displayDatatable();
            }, 3000);
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    displayDatatable() {
      $("#basicExample").DataTable({
        iDisplayLength: 10,
        language: {
          searchPlaceholder: "Search...",
          sSearch: "",
          lengthMenu: "_MENU_ items/page",
          zeroRecords: "No matching records found",
          infoEmpty: "No records available",
          infoFiltered: "(filtered from _MAX_ total records)"
        },
        processing: true
      });
    }
  }
});
